// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-components-about-js": () => import("./../../../src/pages/components/about.js" /* webpackChunkName: "component---src-pages-components-about-js" */),
  "component---src-pages-components-contact-js": () => import("./../../../src/pages/components/contact.js" /* webpackChunkName: "component---src-pages-components-contact-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-head-js": () => import("./../../../src/pages/components/head.js" /* webpackChunkName: "component---src-pages-components-head-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-index-header-js": () => import("./../../../src/pages/components/index-header.js" /* webpackChunkName: "component---src-pages-components-index-header-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-projects-js": () => import("./../../../src/pages/components/projects.js" /* webpackChunkName: "component---src-pages-components-projects-js" */),
  "component---src-pages-components-skills-js": () => import("./../../../src/pages/components/skills.js" /* webpackChunkName: "component---src-pages-components-skills-js" */),
  "component---src-pages-components-testimonials-js": () => import("./../../../src/pages/components/testimonials.js" /* webpackChunkName: "component---src-pages-components-testimonials-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

